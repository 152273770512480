import React from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Call from "../components/Call";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import Intro from "../components/Intro";

const Home = (props) => {
  const intro = props.data.intro;
  const site = props.data.site.siteMetadata;
  const services = props.data.services.edges;
  const features = props.data.features.edges;
  const homeportfolio = props.data.homePortfolio.edges;
  const matches = useMediaQuery("(min-width:800px)");
  const { t } = useTranslation();

  return (
    <Layout bodyClass="page-home">
      <SEO title={t("หน้าหลัก")} />

      <Intro
        imgAlt={t("หน้าหลัก")}
        imgSrc="/images/service/electrostatic-powder-coating.jpg"
        introImageAbsolute={true}
        introImageHideOnMobile={false}
      >
        <div>
          <h1>{t("รับงานพ่นสีฝุ่น อบสีโลหะได้ทุกชนิด")}</h1>
          <p>
            {t(
              "N.R.F. Powder Coating พ่นสีฝุ่นพ่นสีโลหะได้ทุกรูปแบบ รับชิ้นงานขนาดใหญ่ได้สูงสุด 2x4.9x2 ม. ด้วยคุณภาพและตรงต่อเวลา มาตรฐานที่บริษัทอุตสาหกรรมขนาดใหญ่ไว้วางใจ"
            )}
          </p>
        </div>
        <Call showButton />
      </Intro>

      {features.length > 0 && (
        <div className="strip strip-grey">
          <div className="container pt-6 pb-6 pt-md-10 pb-md-10">
            <div className="row justify-content-center">
              {features.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                  <div className="feature">
                    {node.image && (
                      <div className="feature-image">
                        <img src={node.image} alt={node.title} />
                      </div>
                    )}
                    <h2 className="feature-title">{t(node.title)}</h2>
                    <div className="feature-content">{t(node.description)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {homeportfolio.length > 0 && (
        <div className="strip mt-6">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto mb-4">
                <h1 style={{ color: "#2f2f41", fontWeight: "bold" }}>
                  {t("ตัวอย่างผลงาน")}
                </h1>
              </div>
            </div>
          </div>
          <ImageList variant="masonry" cols={matches ? 3 : 2} gap={4}>
            {homeportfolio.map(({ node }) => (
              <ImageListItem key={node.id}>
                <img src={node.image} alt={node.title} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
          <div className="row justify-content-center">
            <div className="col-auto mb-4 mt-4">
              <Link className="button button-primary" to="/portfolio/">
                {t("ชมตัวอย่างผลงานเพิ่มเติม")}
              </Link>
            </div>
          </div>
        </div>
      )}

      {/* service */}
      {services.length > 0 && (
        <div className="strip">
          <div className="container pt-6 pb-6 pb-md-10">
            <div className="row justify-content-start">
              {services.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-4 mb-1">
                  <div className="service service-summary">
                    <div className="service-content">
                      <h2 className="service-title">
                        <Link to={node.fields.slug}>
                          {t(node.frontmatter.title)}
                        </Link>
                      </h2>
                      <p>{node.excerpt}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <Link className="button button-primary" to="/services/">
                  {t("ดูบริการทั้งหมด")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    services: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/services/.*/" },
        frontmatter: { lang: { eq: $language } }
      }
      sort: { fields: [frontmatter___weight], order: ASC }
      limit: 6
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            lang
            date(formatString: "DD MMMM YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    features: allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
    homePortfolio: allHomePortfolioJson {
      edges {
        node {
          id
          title
          image
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
